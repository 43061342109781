import styled from 'styled-components';
import Description from '../Description';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const Preface = () => {
  return (
    <Wrapper>
      <Description fontColor={'white'}>
        <>
          <p>{`As time goes by, I look towards the future. The past remains in our memories.\n`}</p>
          <p>{`Among them, what is digitally recorded exists beyond memories.`}</p>
        </>
      </Description>
    </Wrapper>
  );
};

export default Preface;
