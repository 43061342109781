import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  // bigSkew 시에 가로 스크롤이 생기는 것을 방지하기 위함
  overflow: hidden;
`;

const GlitchText = styled.div`
  color: white;
  transform: skew(var(--skew)) scale(var(--scale));
  &::before,
  &::after {
    content: attr(data-text);
    position: absolute;
    width: 100%;
    transition: clip-path 50ms ease-in;
    background-color: var(--bg-color);
  }

  &::before {
    left: 0.05em;
    text-shadow: 0.02em -0.02em var(--glitch-shadow-color1);
    clip-path: polygon(
      0 var(--top1),
      100% var(--top1),
      100% var(--bottom1),
      0 var(--bottom1)
    );
  }
  &&::after {
    left: -0.05em;
    text-shadow: -0.02em 0.02em var(--glitch-shadow-color2);
    clip-path: polygon(
      0 var(--top2),
      100% var(--top2),
      100% var(--bottom2),
      0 var(--bottom2)
    );
  }
`;

interface GlitchProps {
  text: string;
}

const Glitch = ({ text }: GlitchProps) => {
  const count = useRef(0);
  const glitchTextRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    setInterval(() => {
      const skew = Math.random() * 20 - 10;
      // ::before
      const top1 = Math.random() * 100;
      const bottom1 = Math.random() * 100;
      // ::after
      const top2 = Math.random() * 100;
      const bottom2 = Math.random() * 100;

      if (glitchTextRef.current === null) return;

      glitchTextRef.current.style.setProperty("--top1", `${top1}%`);
      glitchTextRef.current.style.setProperty("--bottom1", `${bottom1}%`);
      glitchTextRef.current.style.setProperty("--top2", `${top2}%`);
      glitchTextRef.current.style.setProperty("--bottom2", `${bottom2}%`);
      glitchTextRef.current.style.setProperty("--skew", `${skew}deg`);
      glitchTextRef.current.style.setProperty("--scale", `1`);

      count.current++;

      if (count.current % 15 === 0) {
        const bigSkew = Math.random() * 180 - 90;
        glitchTextRef.current.style.setProperty("--skew", `${bigSkew}deg`);
      }

      if (count.current % 30 === 0) {
        const bigScale = 1 + Math.random() / 2;
        count.current = 0;
        glitchTextRef.current.style.setProperty("--scale", `${bigScale}`);
      }
    }, 100);
  }, []);
  return (
    <Wrapper>
      <GlitchText ref={glitchTextRef} data-text={text}>
        {text}
      </GlitchText>
    </Wrapper>
  );
};
export default Glitch;
