import React, { useState } from 'react';
import styled from 'styled-components';
import mouseHoverImg from '../../assets/mouse-hover.svg';

export const enum MediaType {
  IMAGE = 'image',
  VIDEO = 'video',
}

const Wrapper = styled.div`
  position: relative;
  display: inline-flex;
  justify-content: center;
`;

const Image = styled.img`
  position: absolute;
  top: 50px;
  opacity: 0;
  transition: var(--hoverSpeed) ease-in-out;
`;

const Video = styled.video`
  position: absolute;
  top: 50px;
  opacity: 0;
  transition: var(--hoverSpeed) ease-in-out;
`;

const Message = styled.div`
  font-family: 'VCR_OSD_MONO';
  color: white;
  &:hover {
    cursor: url(${mouseHoverImg}), auto;
  }
`;

interface HoverImageProps {
  message: string;
  mediaSrc: string;
  mediaWidth?: string;
  mediaType: MediaType;
}

const defaultProps = {
  mediaWidth: 'inherit',
};

const HoverMedia = ({
  message,
  mediaSrc,
  mediaWidth,
  mediaType,
}: HoverImageProps) => {
  const [isShown, setIsShown] = useState(false);

  return (
    <Wrapper>
      <Message
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        style={{
          textShadow: isShown
            ? '2px 2px 0px var(--primary-color), -2px -2px 0px var(--secondary-color)'
            : '',
        }}
      >
        {message}
      </Message>
      {
        {
          image: (
            <Image
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              src={mediaSrc}
              alt=''
              style={{
                visibility: isShown ? 'visible' : 'hidden',
                opacity: isShown ? '1' : '0',
                width: mediaWidth,
              }}
            />
          ),
          video: (
            <Video
              preload='auto'
              autoPlay
              muted
              loop
              playsInline
              onMouseEnter={() => setIsShown(true)}
              onMouseLeave={() => setIsShown(false)}
              src={mediaSrc}
              style={{
                visibility: isShown ? 'visible' : 'hidden',
                opacity: isShown ? '1' : '0',
                width: mediaWidth,
              }}
            />
          ),
        }[mediaType]
      }
    </Wrapper>
  );
};
HoverMedia.defaultProps = defaultProps;

export default HoverMedia;
