import styled from 'styled-components';
import PolarImg from '../../assets/images/polar.png';
import Glitch from '../Glitch/index';
import SocialLink from '../SocialLink';
import Description from '../Description';
import HoverMedia, { MediaType } from '../HoverMedia/index';
import { HoverImages } from '../../assets/hover';
import useScrollPosition from '../../hooks/useScrollPosition';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 28px;
  font-family: 'VCR_OSD_MONO';
`;

const PolarWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
`;

const DescriptionWrapper = styled.div<{ opacity: number }>`
  padding-left: 10%;
  padding-right: 10%;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
  opacity: ${(props) => props.opacity};
  flex: 1;
`;

const GlitchWrapper = styled.div<{ opacity: number }>`
  opacity: ${(props) => props.opacity};
  flex: 1;
`;

const SocialLinkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 50px;
`;

interface PolarProps {
  start1: number;
  start2: number;
  start3: number;
}

const GRADIENT = 2;
const DELTA = 1 / GRADIENT;

const Polar = ({ start1, start2, start3 }: PolarProps) => {
  const [firstOpacity, setFirstOpacity] = useState(0.0);
  const [secondOpacity, setSecondOpacity] = useState(0.0);
  const [thirdOpacity, setThirdOpacity] = useState(0.0);

  const [ratio] = useScrollPosition();
  const [firstVisible, setFirstVisible] = useState(true);
  const [secondVisible, setSecondVisible] = useState(false);
  const [thirdVisible, setThirdVisible] = useState(false);

  useEffect(() => {
    if (ratio > start1 && ratio < start2) {
      setFirstVisible(true);
      setSecondVisible(false);
      setThirdVisible(false);
    } else if (ratio >= start2 && ratio < start3) {
      setFirstVisible(false);
      setSecondVisible(true);
      setThirdVisible(false);
    } else if (ratio >= start3) {
      setFirstVisible(false);
      setSecondVisible(false);
      setThirdVisible(true);
    }

    if (ratio >= start1 && ratio < start1 + DELTA) {
      setFirstOpacity((ratio - start1) * GRADIENT);
    } else if (ratio > start2 - DELTA && ratio <= start2) {
      setFirstOpacity((ratio - start2) * -GRADIENT);
    } else if (ratio >= start1 + DELTA && ratio <= start2 - DELTA) {
      setFirstOpacity(1);
    }

    if (ratio >= start2 && ratio < start2 + DELTA) {
      setSecondOpacity((ratio - start2) * GRADIENT);
    } else if (ratio > start3 - DELTA && ratio <= start3) {
      setSecondOpacity((ratio - start2) * -GRADIENT);
    } else if (ratio >= start2 + DELTA && ratio <= start3 - DELTA) {
      setSecondOpacity(1);
    }

    if (ratio >= start3 && ratio < start3 + DELTA) {
      setThirdOpacity((ratio - start3) * GRADIENT);
    }
    //   else if (ratio >= start1 + DELTA && ratio <= start2 - DELTA) {
    //     setFirstOpacity(1);
    //   }
  }, [
    ratio,
    start1,
    start2,
    start3,
    firstVisible,
    secondVisible,
    thirdVisible,
  ]);
  return (
    <Wrapper>
      <PolarWrapper>
        <img width='120px' src={PolarImg} alt='polar' />
      </PolarWrapper>

      {firstVisible && (
        <GlitchWrapper opacity={firstOpacity}>
          <Glitch text='POLAR X Seonglib' />
        </GlitchWrapper>
      )}
      {secondVisible && (
        <DescriptionWrapper opacity={secondOpacity}>
          <Description>
            <p>
              {`Artist Seonglib(b. 1991, Korea) creates his own unique world of art including the concept of repetitively appearing lines. He held solo exhibitions; `}
              <HoverMedia
                message='<흩어진 파편들>'
                mediaSrc={HoverImages.exhibition1}
                mediaType={MediaType.IMAGE}
                mediaWidth='300px'
              />
              {`, `}
              <HoverMedia
                message='<작-업으로서의: 드로잉>'
                mediaSrc={HoverImages.exhibition2}
                mediaType={MediaType.IMAGE}
                mediaWidth='300px'
              />
              {` and a number of group exhibitions. He tries to break the limits of drawing on paper by applying projects of various medium. This leads to using different types of material from steel, ceramic, fabric to paper. He also tries to cross the boundaries of media through collaborating with artistic brands; PRADA, Yido Pottery, DESCENTE and OFF-WHITE.`}
            </p>
          </Description>
        </DescriptionWrapper>
      )}
      {thirdVisible && (
        <DescriptionWrapper opacity={1}>
          <Description>
            <SocialLinkWrapper>
              <SocialLink
                isInstagram
                title='seonglib’s instagram'
                link='https://instagram.com/seonglib'
              />
              <SocialLink
                title='seonglib’s website'
                link='http://seonglib.com/'
              />
              <SocialLink title='POLAR website' link='https://polar.art' />
            </SocialLinkWrapper>
          </Description>
        </DescriptionWrapper>
      )}
    </Wrapper>
  );
};

export default Polar;
