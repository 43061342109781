import styled from 'styled-components';
import Description from '../Description';
import Typewriter from '../Typewriter';
import SignatureImage from '../../assets/images/signature.png';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const SignatureContainer = styled.img`
  width: 180px;
  margin: 40px;
`;

const AboutArtist = () => {
  return (
    <Wrapper>
      <Typewriter title='ABOUT ARTIST' marginBottom='32px' infiniteBlink />
      <SignatureContainer
        width={'180px'}
        src={SignatureImage}
        alt={'Seonglib.'}
      />
      <Description delay={1.5}>
        <p>
          {`Animation drawing has been started at 2011, and 12 years have pasted since. In the earlier yeras, Seonglib drew on paper using a pencil and scanned the image to convert to a digital format. The digital format could be applied to various medium including screen, fabric, steel and ceramic. Then came the idea of creating the project to an animation format. In 2015, after 4 years of drawing, he continued to animate drawings by filming 15 to 20 images in a second. And now, the entire artwork is created digitally.`}
        </p>
      </Description>
    </Wrapper>
  );
};

export default AboutArtist;
