import exhibition1 from './images/exhibition1.png';
import exhibition2 from './images/exhibition2.png';
import projector from './images/projector.png';
import slideNFTs from './videos/slideNFTs.mp4';

export const HoverImages = {
  exhibition1,
  exhibition2,
  projector,
};

export const HoverVideos = {
  slideNFTs,
};
