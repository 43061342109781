import styled from 'styled-components';
import Description from '../Description';
import Typewriter from '../Typewriter';
import HoverMedia, { MediaType } from '../HoverMedia/index';
import { HoverImages, HoverVideos } from '../../assets/hover';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const HowItWorks = () => {
  return (
    <Wrapper>
      <Typewriter title='HOW IT WORKS' marginBottom='120px' infiniteBlink />
      <Description delay={1.5}>
        <p>
          {`Animation projects produced from year 2015 to 2022 have been exported to 8 slides, and one represents products of each year.`}
          <HoverMedia
            message='PROJECTOR NFT'
            mediaSrc={HoverImages.projector}
            mediaType={MediaType.IMAGE}
            mediaWidth='300px'
          />
          {` plays role of a video player, and `}
          <HoverMedia
            message='SLIDE NFTs'
            mediaSrc={HoverVideos.slideNFTs}
            mediaType={MediaType.VIDEO}
            mediaWidth='300px'
          />
          {` work as a video tap. the PROJECTOR and the SLIDE only plays as a whole when the other part comes together. As so, the whole video could be played when the PROJECTOR NFT and the SLIDE NFT are collected in the same wallet. When various slides are collected in a wallet, the video plays various slides in a seamless manner.`}
        </p>
      </Description>
    </Wrapper>
  );
};

export default HowItWorks;
