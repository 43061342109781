const deviceSizes = {
  mobile: 768,
};

const device = {
  mobile: `screen and (max-width: ${deviceSizes.mobile}px)`,
};

const theme = {
  device,
};
export { deviceSizes };
export default theme;
