import { useEffect, useState } from 'react';
import styled from 'styled-components';
import video1 from '../../assets/videos/video1.mp4';
import video1Mobile from '../../assets/videos/video1_m.mp4';
import isMobile from '../../utils/isMobile';
import useScrollPosition from '../../hooks/useScrollPosition';

const VideoWrapper = styled.div<{ visible: boolean }>`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: ${(props) => (props.visible ? 1 : 0)};
`;

const VideoContainer = styled.video<{
  grayscaleAmount: number;
  opacity: number;
  visible: boolean;
}>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  filter: grayscale(${(props) => props.grayscaleAmount});
  opacity: ${(props) => props.opacity};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
`;

const FINAL_OPACITY = 0.3;

const VideoBackground = () => {
  const [opacity, setOpacity] = useState(1.0);
  const [visible, setVisible] = useState(true);
  const [ratio] = useScrollPosition();

  useEffect(() => {
    if (ratio <= 1) {
      setOpacity(ratio * -FINAL_OPACITY + 1);
      setVisible(true);
    } else if (ratio > 8) {
      setOpacity(0);
      setVisible(false);
    } else {
      setOpacity(FINAL_OPACITY);
      setVisible(true);
    }
  }, [ratio]);

  return (
    <VideoWrapper visible={visible}>
      {visible && (
        <VideoContainer
          preload='auto'
          autoPlay
          muted
          loop
          playsInline
          grayscaleAmount={ratio}
          opacity={opacity}
          visible={visible}
        >
          <source src={isMobile() ? video1Mobile : video1} type='video/mp4' />
        </VideoContainer>
      )}
    </VideoWrapper>
  );
};

export default VideoBackground;
