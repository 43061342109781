import { useEffect, useState } from 'react';
import styled from 'styled-components';
import useScrollPosition from '../../hooks/useScrollPosition';

const Wrapper = styled.div<{ opacity: number }>`
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: ${(props) => props.opacity};
`;

interface FadeInAndOutProps {
  start: number;
  end: number;
  children: JSX.Element;
}

const GRADIENT = 1;
const DELTA = 1 / GRADIENT;

const FadeInAndOut = ({ start, end, children }: FadeInAndOutProps) => {
  const [opacity, setOpacity] = useState(0.0);
  const [show, setShow] = useState(false);
  const [ratio] = useScrollPosition();

  useEffect(() => {
    if (ratio < start || ratio > end) {
      setOpacity(0);
      setShow(false);
    } else if (ratio >= start && ratio < start + DELTA) {
      setOpacity((ratio - start) * GRADIENT);
    } else if (ratio > end - DELTA && ratio <= end) {
      setOpacity((ratio - end) * -GRADIENT);
    } else if (ratio >= start + DELTA && ratio <= end - DELTA) {
      setOpacity(1);
    }

    if (opacity > 0) {
      setShow(true);
    }
  }, [ratio, start, end, show, opacity]);

  return <>{show && <Wrapper opacity={opacity}>{children}</Wrapper>}</>;
};

export default FadeInAndOut;
