import styled, { keyframes } from 'styled-components';

interface DescriptionProps {
  delay: number;
  children: JSX.Element;
  fontColor: string;
}

const fadeInUp = keyframes`
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const MessageParagraph = styled.p<{ delay: number; fontColor: string }>`
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 14px;
    padding-left: 5%;
    padding-right: 5%;
  }

  font-family: 'VCR_OSD_MONO';
  font-size: 20px;
  color: ${(props) => props.fontColor};
  text-shadow: 2px 2px 0px var(--shadow-color);
  opacity: 0;
  transform: translateY(3rem);
  animation: ${fadeInUp} 2s ease ${(props) => props.delay}s forwards;
  line-height: 200%;
`;

const Description = ({ delay, fontColor, children }: DescriptionProps) => {
  return (
    <div>
      <MessageParagraph delay={delay} fontColor={fontColor}>
        {children}
      </MessageParagraph>
    </div>
  );
};

Description.defaultProps = {
  delay: 0,
  fontColor: 'var(--text-color)',
};

export default Description;
