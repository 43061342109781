import filmImg1 from './film_01.png';
import filmImg2 from './film_02.png';
import filmImg3 from './film_03.png';
import filmImg4 from './film_04.png';
import filmImg5 from './film_05.png';
import filmImg6 from './film_06.png';
import filmImg7 from './film_07.png';
import filmImg8 from './film_08.png';

import filmImgMobile1 from './film_01_m.png';
import filmImgMobile2 from './film_02_m.png';
import filmImgMobile3 from './film_03_m.png';
import filmImgMobile4 from './film_04_m.png';
import filmImgMobile5 from './film_05_m.png';
import filmImgMobile6 from './film_06_m.png';
import filmImgMobile7 from './film_07_m.png';
import filmImgMobile8 from './film_08_m.png';

export const Films = Array.of(
  filmImg1,
  filmImg2,
  filmImg3,
  filmImg4,
  filmImg5,
  filmImg6,
  filmImg7,
  filmImg8
);

export const FilmsMobile = Array.of(
  filmImgMobile1,
  filmImgMobile2,
  filmImgMobile3,
  filmImgMobile4,
  filmImgMobile5,
  filmImgMobile6,
  filmImgMobile7,
  filmImgMobile8
);
