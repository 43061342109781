import filmVideo1 from './2015.mp4';
import filmVideo2 from './2016.mp4';
import filmVideo3 from './2017.mp4';
import filmVideo4 from './2018.mp4';
import filmVideo5 from './2019.mp4';
import filmVideo6 from './2020.mp4';
import filmVideo7 from './2021.mp4';
import filmVideo8 from './2022.mp4';

export const FilmVideos = Array.of(
  filmVideo1,
  filmVideo2,
  filmVideo3,
  filmVideo4,
  filmVideo5,
  filmVideo6,
  filmVideo7,
  filmVideo8
);
