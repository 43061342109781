import styled, { keyframes } from 'styled-components';

const typewriter = keyframes`
  to {
    left: 100%;
  }
`;

const blink = keyframes`
  to {
    background: white;
  }
`;

const Title = styled.h1<{
  delay: number;
  length: number;
  isSubTitle: boolean;
  fontSize: number;
  marginBottom: string;
  infiniteBlink: boolean;
}>`
  @media ${({ theme }) => theme.device.mobile} {
    font-size: ${(props) =>
      props.isSubTitle ? props.fontSize + 'px' : '40px'};
  }
  font-family: 'VCR_OSD_MONO';
  width: max-content;
  padding: 3px;
  position: relative;
  color: white;
  text-shadow: ${(props) =>
    props.isSubTitle
      ? '2px 2px 0px var(--shadow-color)'
      : '4px 4px 0px var(--primary-color)'};
  font-size: ${(props) => (props.isSubTitle ? props.fontSize + 'px' : '80px')};
  margin-block-start: 0;
  margin-block-end: 0;

  margin-bottom: ${(props) => props.marginBottom};

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &:before {
    background: var(--bg-color);
    animation: ${typewriter} var(--typewriterSpeed)
      steps(${(props) => props.length}) ${(props) => props.delay}s forwards;
  }

  &:after {
    width: 0.125em;
    background: transparent;
    animation: ${typewriter} var(--typewriterSpeed)
        steps(${(props) => props.length}) ${(props) => props.delay}s forwards,
      ${blink} 750ms steps(${(props) => props.length})
        ${(props) => props.delay}s
        ${(props) => (props.infiniteBlink ? 'infinite' : '3')};
  }
`;

interface TypewriteProps {
  title: string;
  delay: number;
  isSubTitle: boolean;
  fontSize: number;
  marginBottom: string;
  infiniteBlink: boolean;
}

const Typewriter = ({
  title,
  delay,
  isSubTitle,
  fontSize,
  marginBottom,
  infiniteBlink,
}: TypewriteProps) => {
  return (
    <div>
      <Title
        delay={delay}
        length={title.length}
        isSubTitle={isSubTitle}
        fontSize={fontSize}
        marginBottom={marginBottom}
        infiniteBlink={infiniteBlink}
      >
        {title}
      </Title>
    </div>
  );
};

Typewriter.defaultProps = {
  delay: 0,
  isSubTitle: false,
  fontSize: 20,
  marginBottom: '5px',
  infiniteBlink: false,
};

export default Typewriter;
