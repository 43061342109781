import styled from 'styled-components';
import Description from '../Description';
import Typewriter from '../Typewriter';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  & p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
`;

const Introduction = () => {
  return (
    <Wrapper>
      <Typewriter title='PROJECTOR' marginBottom='32px' />
      <Typewriter
        title='of artist Seonglib'
        delay={1.5}
        isSubTitle
        marginBottom='78px'
      />
      <Typewriter
        title='2015 - 2022'
        delay={3}
        isSubTitle
        fontSize={24}
        marginBottom='80px'
        infiniteBlink
      />
      <Description delay={4.5}>
        <p>
          {`In the era of NFT, project “PROJECTOR” starts by questioning: “Can the
          past and the future of myself coexist within the digital world which
          lacks concept of time?” We believe that this is realizable in a space
          that does not go after the notion of time, and with the support of NFT
          technology.Through this work, we aim to handle experiments that
          combine NFT with previous projects. Time, possibilities and creating
          an eternal space.`}
        </p>
      </Description>
    </Wrapper>
  );
};

export default Introduction;
