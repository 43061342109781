import { useEffect, useState } from 'react';

const useScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [ratio, setRatio] = useState(0.0);

  useEffect(() => {
    const updatePosition = () => {
      setScrollPosition(window.pageYOffset);
    };
    const updateRatio = () => {
      setRatio(window.pageYOffset / window.innerHeight);
    };
    window.addEventListener('scroll', updatePosition);
    window.addEventListener('scroll', updateRatio);
    updatePosition();
    return () => {
      window.removeEventListener('scroll', updatePosition);
      window.removeEventListener('scroll', updateRatio);
    };
  }, []);

  return [ratio, scrollPosition];
};

export default useScrollPosition;
