import './App.css';
import { ThemeProvider } from 'styled-components';
import theme from './theme';
import VideoBackground from './components/VideoBackground';
import FadeInAndOut from './components/FadeInAndOut';
import Preface from './components/pages/Preface';
import Introduction from './components/pages/Introduction';
import HowItWorks from './components/pages/HowItWorks';
import HorizontalFilm from './components/pages/HorizontalFilm';
import AboutArtist from './components/pages/AboutArtist';
import Polar from './components/pages/Polar';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className='App'>
        <VideoBackground />
        <FadeInAndOut start={2} end={5}>
          <Preface />
        </FadeInAndOut>
        <FadeInAndOut start={5} end={8}>
          <Introduction />
        </FadeInAndOut>
        <FadeInAndOut start={5} end={8}>
          <Introduction />
        </FadeInAndOut>
        <FadeInAndOut start={8} end={16}>
          <HorizontalFilm />
        </FadeInAndOut>
        <FadeInAndOut start={16} end={19}>
          <HowItWorks />
        </FadeInAndOut>
        <FadeInAndOut start={19} end={22}>
          <AboutArtist />
        </FadeInAndOut>
        <FadeInAndOut start={22} end={29}>
          <Polar start1={22} start2={25} start3={28} />
        </FadeInAndOut>
      </div>
    </ThemeProvider>
  );
}

export default App;
