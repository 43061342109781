import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Films, FilmsMobile } from '../../assets/images/film';
import useScrollPosition from '../../hooks/useScrollPosition';
import video3 from '../../assets/videos/video3.mp4';
import video3Mobile from '../../assets/videos/video3_m.mp4';
import { FilmVideos } from '../../assets/videos/filmVideo';
import Typewriter from '../Typewriter';
import isMobile from '../../utils/isMobile';
import Ex from '../../assets/ex.svg';

const PageWrapper = styled.div`
  display: flex;
  height: 100vh;
`;
const Wrapper = styled.div<{ left: number }>`
  @media ${({ theme }) => theme.device.mobile} {
    flex-direction: column;
    left: 0;
    top: -${(props) => props.left}%;
  }

  position: absolute;
  height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  left: -${(props) => props.left}%;
  z-index: 50;
`;

const Header = styled.div`
  height: 10vh;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const FileImgContainer = styled.div<{ img: string; mobileImg: string }>`
  @media ${({ theme }) => theme.device.mobile} {
    width: ${window.innerWidth}px;
    height: ${window.innerHeight / 4}px;
    background-image: url(${(props) => props.mobileImg});
  }
  width: ${window.innerWidth / 4}px;
  height: ${(window.innerWidth / 4) * 1.5653}px;
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-blend-mode: lighten;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const YearContainer = styled.p`
  font-family: digital_7;
  color: white;
  font-size: 4rem;
  transition: color 1s, text-shadow 1s;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #fff;
  &:hover {
    color: var(--primary-color);
    text-shadow: 0 0 10px var(--primary-color), 0 0 20px var(--primary-color),
      0 0 30px var(--primary-color);
  }
`;

const ModalYearContainer = styled.p`
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 16px;
  }
  font-family: VCR_OSD_MONO;
  color: white;
  font-size: 24px;
  text-shadow: 0 0 10px #fff, 0 0 20px #fff;
`;

const ModalWrapper = styled.div<{ visible: boolean }>`
  box-sizing: border-box;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: fixed;
  width: ${window.innerWidth}px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  outline: 0;
`;

const ModalInner = styled.div`
  @media ${({ theme }) => theme.device.mobile} {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
  }

  box-sizing: border-box;
  position: relative;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
  background-color: black;
  color: white;
  border-radius: 10px;
  top: 50%;
  transform: translateY(-50%);
  margin: 0 auto;
  padding: 40px 20px;
`;

const VideoWrapper = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  z-index: 0;
`;

const VideoContainer = styled.video`
  @media ${({ theme }) => theme.device.mobile} {
    height: 100%;
    filter: brightness(1);
  }
  position: fixed;
  top: 50%;
  left: 50%;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  z-index: -1;
  filter: brightness(0.5);
`;

const ModalVideoContainer = styled.video`
  width: 80%;
  max-height: 80vh;
`;

const ExContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const ExImage = styled.img`
  width: 48px;
  &:hover {
    cursor: pointer;
  }
`;

let timeoutObject: NodeJS.Timeout | null;

const HorizontalFilm = () => {
  const [ratio] = useScrollPosition();
  const [left, setLeft] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(2015);

  useEffect(() => {
    if (ratio > 9 && ratio < 15) {
      setLeft((50 * ratio) / 3 - 150);
    }
  }, [ratio]);

  const handleMouseEnter = (index: number) => {
    const timeoutObj = setTimeout(() => {
      setModalIndex(index);
      setModalVisible(true);
    }, 1000);
    timeoutObject = timeoutObj;
  };

  const handleMouseLeave = () => {
    if (timeoutObject != null) {
      clearTimeout(timeoutObject);
    }
  };

  return (
    <>
      <VideoWrapper>
        <VideoContainer preload='auto' autoPlay muted loop playsInline>
          <source src={isMobile() ? video3Mobile : video3} type='video/mp4' />
        </VideoContainer>
      </VideoWrapper>
      <PageWrapper>
        <Header>
          {!modalVisible && (
            <Typewriter
              title={isMobile() ? 'tap each year' : 'hover your mouse'}
              isSubTitle
              fontSize={20}
              infiniteBlink
            />
          )}
        </Header>
        <Wrapper left={left}>
          {Films.map((item, i) => (
            <FileImgContainer img={Films[i]} mobileImg={FilmsMobile[i]}>
              <YearContainer
                onMouseEnter={() => {
                  handleMouseEnter(i);
                }}
                onMouseLeave={handleMouseLeave}
              >
                {2015 + i}
              </YearContainer>
            </FileImgContainer>
          ))}

          <ModalWrapper
            visible={modalVisible}
            onMouseMove={() => {
              if (modalVisible) setModalVisible(false);
            }}
          >
            <ModalInner className='modal-inner'>
              {isMobile() && (
                <ModalYearContainer>{modalIndex + 2015}</ModalYearContainer>
              )}
              {modalVisible && (
                <ModalVideoContainer
                  preload='auto'
                  autoPlay
                  muted
                  loop
                  playsInline
                >
                  <source src={FilmVideos[modalIndex]} type='video/mp4' />
                </ModalVideoContainer>
              )}
              {!isMobile() && (
                <ModalYearContainer>{modalIndex + 2015}</ModalYearContainer>
              )}
              {isMobile() && (
                <ExContainer>
                  <ExImage
                    src={Ex}
                    alt='close'
                    onClick={() => {
                      setModalVisible(false);
                    }}
                  />
                </ExContainer>
              )}
            </ModalInner>
          </ModalWrapper>
        </Wrapper>
      </PageWrapper>
    </>
  );
};

export default HorizontalFilm;
