import styled from 'styled-components';
import instagram from '../assets/instagram.svg';
import website from '../assets/website.svg';
import mouseHoverImg from '../assets/mouse-hover.svg';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: white;
  width: 80vw;
  margin-bottom: 15px;
`;

const Title = styled.span`
  @media ${({ theme }) => theme.device.mobile} {
    font-size: 14px;
  }
  font-size: 20px;
  font-family: 'VCR_OSD_MONO';
  &:hover {
    cursor: url(${mouseHoverImg}), auto;
  }
`;

const Image = styled.img`
  @media ${({ theme }) => theme.device.mobile} {
    width: 20px;
  }
  width: 28px;
  margin-right: 10px;
  &:hover {
    cursor: url(${mouseHoverImg}), auto;
  }
`;

interface SocialLinkProps {
  isInstagram: boolean;
  title: string;
  link: string;
}

const handleClick = (link: string) => {
  window.open(link);
};

const SocialLink = ({ isInstagram, title, link }: SocialLinkProps) => {
  return (
    <Wrapper>
      <Image
        src={isInstagram ? instagram : website}
        alt='SocialLink'
        onClick={() => {
          handleClick(link);
        }}
      />
      <Title
        onClick={() => {
          handleClick(link);
        }}
      >
        {title}
      </Title>
    </Wrapper>
  );
};

SocialLink.defaultProps = {
  isInstagram: false,
  link: '#',
};

export default SocialLink;
